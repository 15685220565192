<template>
  <div>
    <h2 v-html="$t('search_warning_title')"></h2>
    <v-select
      id="search_warning_dropdown"
      v-model="searchCardStore.ProcessingFormValidationWarnings"
      :label="$t('search_warning_dropdown')"
      :items="warning"
      item-title="text"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from '@/components/searchCards/baseSearch.vue'
import { defineComponent } from 'vue'
import { useSearchWarningStore } from '@/store/SearchWarningStore'

export default defineComponent({
  name: 'searchWarning',
  extends: baseSearchVue,
  data() {
    return {
      warning: [
        { value: 1, text: this.$t('search_warning_dropdown_yes') },
        { value: 0, text: this.$t('search_warning_dropdown_no') }
      ]
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchWarningStore(), true)
  }
})
</script>
